import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import "./scss/custom.scss";

import { CIcon } from "@coreui/icons-vue";
import { cilExitToApp, cilUser, cilSearch } from "@coreui/icons";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import specific icons */
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(faCircleQuestion, faUserSecret);

const icons = {
  cilExitToApp,
  cilUser,
  cilSearch,
};

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(router);
app.provide("icons", icons);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("CIcon", CIcon);
app.mount("#app");
